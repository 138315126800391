// @ts-nocheck
import Head from "next/head"

import {getStoryblokApi, StoryblokComponent, useStoryblokState} from "@storyblok/react"
import BaseLayout from "../components/layouts/BaseLayout";

export default function Home(props: any) {
    let story = props.story;

    story = useStoryblokState(story);

    const metaTitle = story.content?.meta?.title || 'Pacific Codeline LLC';
    const canonical = 'https://www.pacificcodeline.com' + story.path || '';

    return (
        <div className={"bg-warm-gray-50"}>

            <Head>
                <title>{metaTitle}</title>
                <meta name={'description'} content={story?.content?.meta?.description}/>
                <link rel="canonical" href={canonical}/>

                <link rel="icon" href="/favicon.png"/>
            </Head>

            <header>
                {/*<h1>*/}
                {/*    { props.story ? props.story.name : 'My Site' }*/}
                {/*</h1>*/}
            </header>

            <BaseLayout
                navigation={props.navigation}
                footer={props.footer}
                invert={props.story.content.invert_header}
            >
                <StoryblokComponent blok={story.content}/>

            </BaseLayout>
        </div>
    )
}

interface IStaticProps {
    preview: Boolean,
    params: { slug: Array<string> }
}

export async function getStaticProps({preview, params}: IStaticProps) {
    // home is the default slug for the homepage in Storyblok
    let slug = "home";

    // load the draft version
    let sbParams = {
        version: "published", // or 'published',
        resolve_relations: "SuccessStoriesSmall.posts,Relationship.story,SuccessStorySquares.story",
    };

    //is dev
    if (process.env.NODE_ENV === 'development') {
        preview = true;
    }
    if (preview) {
        sbParams.version = "draft";
    }

    const storyblokApi = getStoryblokApi();
    let {data} = await storyblokApi.get(`cdn/stories/${slug}`, sbParams);
    let navigation = await storyblokApi.get(`cdn/stories/mainnavigation`, sbParams);
    let footer = await storyblokApi.get(`cdn/stories/footer`, sbParams);

    return {
        props: {
            navigation: navigation ? navigation.data.story : false,
            footer: footer ? footer.data.story : false,
            story: data ? data.story : false,
            key: data ? data.story.id : false,
        },
        revalidate: 60*60, // revalidate every hour
    };
}